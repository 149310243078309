import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { pdficon, download_pdf_icon, downloadbannerimg } from "../Assets/index";
import { AppContext } from "../ContextApi";

function CashlessModal(props) {
    const {data} = props
    const  {constantsApiData } = useContext(AppContext);

    const handleDownload = (url) => {
      const EcardsUrl = constantsApiData.path.E_CARDS_DATA_PATH;
      if (url.startsWith("https://")) {
        window.open(url, "_blank");
      } else {
        window.open(`${EcardsUrl}/${url}`, "_blank");
      }
    };

  return (
    <Modal {...props} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Download documents {props.keyvalue}</Modal.Title>
        <Button onClick={props.handleClose}>x</Button>
      </Modal.Header>
      <Modal.Body>
        <div className="pdf-download-cover-wrapper">
          <div className="row">
          {data?.map((elem) =>{
            return <div className="col-lg-4">
              <div className="download-pdf-box">
                <div className="pdf-img-box">
                  <img src={pdficon}></img>
                </div>
                <div className="pdf-name-box">
                  <h5>{elem.original_file_name}</h5>
                </div>

                <div className="pdf-download-icon-box" onClick={() =>handleDownload(elem.file_name)}>
                    <img src={download_pdf_icon}></img>
                </div>
              </div>
            </div>
          })}
            
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CashlessModal;

