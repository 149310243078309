import React from 'react'

const ErrorData = () => {
  return (
    <>
        <h5>Error Data is not Fetched</h5>
    </>
  )
}

export default ErrorData
