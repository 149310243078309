import Modal from "react-bootstrap/Modal";

function ClaimModal(props) {
  const {data ,keyvalue} = props
  console.log(data[keyvalue].insured_name)
  return (
    <div className="claim_modal_main_wrapper">
      <Modal {...props} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton className="claim_status_modal_header">
        </Modal.Header>
        <Modal.Body className="claim_status_modal-content">
          <div className="Track_your_claim_main_wrapper">
            <div className="Track_your_claim_header">
               <h4>Track Your Claim Status</h4>
            </div>
            <div className="Track_your_claim_details_box">
                <div className="row">

                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Name of Insured</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.insured_name !== "" && data[keyvalue]?.insured_name !== null? data[keyvalue]?.insured_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Patient Name</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.patient_name !== "" && data[keyvalue]?.patient_name !== null? data[keyvalue]?.patient_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Hospital Name</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.hospital_name !== "" && data[keyvalue]?.hospital_name !== null? data[keyvalue]?.hospital_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Relation</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.relation !== "" && data[keyvalue]?.relation !== null? data[keyvalue]?.relation : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Date of Admission</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.date_of_hospitalization !== "" && data[keyvalue]?.date_of_hospitalization !== null? data[keyvalue]?.date_of_hospitalization : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Date of Discharge</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{data[keyvalue]?.date_of_discharge !== "" && data[keyvalue]?.date_of_discharge !== null ? data[keyvalue]?.date_of_discharge : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Number</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.insurance_claim_no !== ""  && data[keyvalue]?.insurance_claim_no !== null? data[keyvalue]?.insurance_claim_no : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.status !== "" && data[keyvalue]?.status !== null ? data[keyvalue]?.status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Outstanding claim status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.outstanding_claim_status !== "" && data[keyvalue]?.outstanding_claim_status !== null  ? data[keyvalue]?.outstanding_claim_status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Type</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.claim_type !== "" && data[keyvalue]?.claim_type !== null ? data[keyvalue]?.claim_type : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Network Status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.network_status !== "" && data[keyvalue]?.network_status !== null ? data[keyvalue]?.network_status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Registered data</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.claim_registered_date !== "" && data[keyvalue]?.claim_registered_date !== null ? data[keyvalue]?.claim_registered_date : "-"}</h5>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Disease Category</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.disease_category !== "" && data[keyvalue]?.disease_category !== null? data[keyvalue]?.disease_category : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>File recieved Date</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.file_received_date !== "" && data[keyvalue]?.file_received_date !== null ? data[keyvalue]?.file_received_date : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Amount</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.claim_amount !== "" && data[keyvalue]?.claim_amount !== null ? data[keyvalue]?.claim_amount : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Deduction Amount</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.deduction_amount !== "" && data[keyvalue]?.deduction_amount !== null ? data[keyvalue]?.deduction_amount : "-"}</h5>

                    </div>
                  </div>
                  
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Deduction Reason</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.deduction_reason !== "" && data[keyvalue]?.deduction_reason !== null ? data[keyvalue]?.deduction_reason : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Amount Claimed</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.amount_claimed !== "" && data[keyvalue]?.amount_claimed !== null ? data[keyvalue]?.amount_claimed : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Amount Paid Date</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.amount_paid_date !== "" && data[keyvalue]?.amount_paid_date !== null ? data[keyvalue]?.amount_paid_date : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Reason For closure</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{data[keyvalue]?.reason_for_closure !== "" && data[keyvalue]?.reason_for_closure !== null ? data[keyvalue]?.reason_for_closure : "-"}</h5>
                    </div>
                  </div>

                </div>
                
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ClaimModal;
