import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import { formatNumber } from "../Utils";
import { AppContext } from "../ContextApi";
import { toast, ToastContainer } from "react-toastify";
import { NotokenFound ,formatDateTimeFormat  ,convertTo12HourFormat ,TokenRefresh} from "../Utils";
import {clock} from "../Assets/index";

const Attendence_employee = () => {
  const { requestOptions, setRequestOptions ,AuthToken , setAuthToken} = useContext(AppContext);
  const [monthlyattendenceData, setmonthlyattendenceData] = useState([]);
  const [punchData, setPunchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [punchinHours, setPunchInHours] = useState(0);
  const [Hours, setHours] = useState(0);
  const [Minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isTimeRunning, setisTimeRunning] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  const [BreakTimeCount, setBreakTimeCount] = useState(0);
  const [overTimeCount, setOverTimeCount] = useState(0);
  const [isPunchIn, setisPunchIn] = useState(localStorage.getItem('puchStatus'));
  const [punchInStartTime, setPunchInStartTime] = useState(null);
  const [isSearched, setisSearched] = useState(false);
  const [Errorfields, setErrorfeilds] = useState([]);
  const navigate = useNavigate();
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [punchStatus , setPunchStatus] = useState(
    localStorage.getItem('puchStatus') || ""
  )
  const [token , setToken] = useState(localStorage.getItem("authorizationToken"))
  const [UnAuthorized , setUnAuthorized] = useState(null)
  const [currentStatus , setCurrentStatus] = useState(null)
  const [Total_punchInDuration , setTotalPunchInDuration] = useState(null)


  const columns = [
    {
      name: "Sr No",
      selector: (row , index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Punch In",
      selector: (row) => row.punch_in_time ? convertTo12HourFormat(row.punch_in_time) : "",
    },
    {
      name: "Punch Out",
      selector: (row) => row.punch_out_time ? convertTo12HourFormat(row.punch_out_time) : "-",
    },
  ];

  // console.log(punchStatus)

  useEffect(() => {
    let timerInterval;

    if (currentStatus === 0) {
      timerInterval = setInterval(() => {
        if (seconds < 59) {
          setSeconds(seconds + 1);
        } else if (Minutes < 59) {
          setSeconds(0);
          setMinutes(Minutes + 1);
        } else {
          setSeconds(0);
          setMinutes(0);
          setHours(Hours + 1);
        }
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [Hours, Minutes, seconds, currentStatus]);


  async function handleTokenInResponse(response) {
    // console.log(token)
    if (response.status === 401 || token === "") {
      setUnAuthorized(true)
      return response;
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        setUnAuthorized(false)
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setAuthToken(authorizationToken)
        setToken(authorizationToken);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    localStorage.setItem(
      "timerState",
      JSON.stringify({
        hours: Hours,
        minutes: Minutes,
        seconds: seconds,
        running: isTimeRunning,
      })
    );
  }, [Hours, Minutes, seconds, isTimeRunning]);

  
  const HandlePunch = () => {
    console.log(currentStatus)
    console.log(isPunchIn)
    const data = {
      punch_type: currentStatus == "0" ? "1" : "0",
      lat: `${Latitude}`,
      long: `${Longitude}`,
      source: "0",
    };

    const requestOptionsCopy = {...requestOptions}
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(data) ;
    requestOptionsCopy.headers.Authorization = localStorage.getItem("authorizationToken");

    async function CallpunchApi() {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL +
          `/employee/employee-punch`,
          requestOptionsCopy
        );
        const updatedResponse = await handleTokenInResponse(response);

        if (updatedResponse.ok) {
          const res = await updatedResponse.json();
          if (res.success) {
            // console.log(res);
            setisPunchIn(!isPunchIn);
            localStorage.setItem('puchStatus', !isPunchIn);
            setisTimeRunning(!isTimeRunning);
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            GetData();
            GetCurrentStatusTimer();
            // parseTotalPunchInDuration();
          } else {
            setErrorfeilds(res.errors_fields);
            toast.error(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        } else {
          console.error(
            `Request failed with status: ${updatedResponse.status}`
          );
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      }
    }
    CallpunchApi();
  };

  const GetData = async () => {
    const requestOptionsCopy = {...requestOptions}
    requestOptionsCopy.method = "GET";
    requestOptionsCopy.body = null ;
    requestOptionsCopy.headers.Authorization = localStorage.getItem("authorizationToken");
    try {
      const response = await fetch(process.env.REACT_APP_API_URL +
        "/employee/punching-activity-list",
        requestOptionsCopy
      );
      const updatedResponse = await handleTokenInResponse(response);
      const res = await updatedResponse.json();

      if (res.success) {
        setPunchData(res.data.punch_activity);
        setPunchInStartTime(formatDateTimeFormat(res.data.punch_activity[0].created_at))
        setUnAuthorized(false)
        // console.log();
      }
      // console.log(res);
      // setFilterData(response.products);
    } catch (error) {
      console.log(error);
    }
  };

  const GetMonthlyAttendenceList = async () => {
    const requestOptionsCopy = {...requestOptions}
    requestOptionsCopy.method = "GET";
    requestOptionsCopy.body = null ;
    requestOptionsCopy.headers.Authorization = localStorage.getItem("authorizationToken");
    try {
      const response = await fetch(process.env.REACT_APP_API_URL +
        "/employee/monthly-attendence-list",
        requestOptionsCopy
      );
      const updatedResponse = await handleTokenInResponse(response);
      const res = await updatedResponse.json();

      if (res.success) {
        setmonthlyattendenceData(res.data.monthly_attendence)
        setUnAuthorized(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetCurrentStatusTimer = async () =>{
    const requestOptionsCopy = {...requestOptions}
    requestOptionsCopy.method = "GET";
    requestOptionsCopy.body = null ;
    requestOptionsCopy.headers.Authorization = localStorage.getItem("authorizationToken");
    try {
      const response = await fetch(process.env.REACT_APP_API_URL +
        "/employee/calculate-punch-time",
        requestOptionsCopy
      );
      const updatedResponse = await handleTokenInResponse(response);
      const res = await updatedResponse.json();

      if (res.success) {
        setCurrentStatus(res.data.current_status)
        setTotalPunchInDuration(res.data.totalPunchInDuration)
        const [hourStr, minuteStr, secondStr] = res.data?.totalPunchInDuration?.split(':');
        setHours(parseInt(hourStr, 10));
        setMinutes(parseInt(minuteStr, 10));
        setSeconds(parseInt(secondStr, 10));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  var allowGeoRecall = true;
  var countLocationAttempts = 0;

  function getLocation() {
    console.log("getLocation was called");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, positionError);
    } else {
      console.log("Geolocation is not supported by this device");
    }
  }

  function positionError() {
    toast.error("Geolocation is not enabled", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    if (allowGeoRecall && countLocationAttempts < 5) {
      countLocationAttempts += 1;
      getLocation();
    }
  }

  function showPosition(position) {
    allowGeoRecall = false;
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const HandleSearchinput = (e) => {
    setSearchQuery(e.target.value);
    setFilterData(monthlyattendenceData);
  };

  const handleSearch = () => {
    if (searchQuery === "") {
      setFilterData(monthlyattendenceData);
      console.log(monthlyattendenceData);
    } else {
      const result =
        searchQuery.trim() === ""
          ? monthlyattendenceData
          : monthlyattendenceData.filter((item) => {
              const values = Object.values(item).map((value) => {
                const LowerCaseValue = value.toLowerCase();
                return LowerCaseValue;
                
              });
              console.log(values)
              const includesFilter = values.some((value) =>
                value.includes(searchQuery.toLowerCase())
              );
              return includesFilter;
            });
      setFilterData(result);
      console.log(result)
    }
    setisSearched(true);
  };

  const RowStyles = {
    rows: {
      style: {
        "&:nth-child(even)": {
          backgroundColor: "#f7f7f7",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#ffffff",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        borderTop: "1px solid #f7f7f7",
      },
    },
  };

  useEffect(() => {
    if(UnAuthorized){
      NotokenFound(navigate);
    }
    getLocation();
    GetMonthlyAttendenceList();
    GetCurrentStatusTimer();
  }, [token]);

  useEffect(() =>{
    if(!token ){
      NotokenFound(navigate)
    }
    GetData();
    GetCurrentStatusTimer();
  } ,[])

  return (
    <>
      <Header />
      <div className="attendence-empoyee-main-page-cover-wrapper">
        <div className="attendence-employee-content-cover">
          <div className="attendence-employee-content-box">
            <div className="container">
              <h5>Attendance</h5>
              <div className="attendence-employee-cards-cover">
                <div className="row mt-4">
                  <div className="col-lg-4">
                    <div className="attendence-employee-box">
                      <h4>
                        Timesheet
                        <span className="text-muted-dim">{`${currentDate.getDate()} ${
                          monthNames[currentDate.getMonth()]
                        } ${currentDate.getFullYear()}`}</span>
                      </h4>
                      {punchData && punchData.length > 0 && (
                        <div class="punch-det">
                          <h6>Punch In at</h6>
                          <p>{punchInStartTime}</p>
                        </div>
                      )}
                      <div class="punch-info">
                        <div class="punch-hours">
                          <span>
                            {formatNumber(Hours)}:{formatNumber(Minutes)}:
                            {formatNumber(seconds)}
                          </span>
                        </div>
                      </div>
                      <div class="punch-btn-section">
                        <button
                          type="button"
                          class="btn btn-primary punch-btn"
                          onClick={HandlePunch}
                        >
                          {currentStatus == 0 ? "Punch Out" : "Punch In"}
                        </button>
                      </div>
                      <div class="statistics">
                        <div class="row">
                          <div class="col-md-6 col-6 text-center">
                            <div class="stats-box">
                              <p>Break</p>
                              <h6>{BreakTimeCount} hrs</h6>
                            </div>
                          </div>
                          <div class="col-md-6 col-6 text-center">
                            <div class="stats-box">
                              <p>Overtime</p>
                              <h6>{overTimeCount} hrs</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {punchData && punchData.length > 0 ? (
                    <div className="col-lg-4">
                      <div className="attendence-employee-box attendence-list-box">
                        <h4>Today Activity</h4>
                        <ul class="res-activity-list">
                          {punchData &&
                            punchData.map((data) => {
                              return (
                                <li>
                                  <p class="mb-0">{data.punch_type}</p>
                                  <p class="res-activity-time">
                                    <span class="clock-icon-box">
                                      <img src={clock}/>
                                    </span>
                                    {data.timing}
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="attendence-employee-data-cover">
                  {monthlyattendenceData && (
                    <DataTable
                      columns={columns}
                      data={searchQuery && isSearched ? filterData : monthlyattendenceData}
                      pagination
                      className="custom-datatable"
                      customStyles={RowStyles}
                      subHeader
                      subHeaderComponent={
                        <div className="datatable-header-component">
                          <div className="search-input-box">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search..."
                              value={searchQuery}
                              onChange={(e) => HandleSearchinput(e)}
                            />
                          </div>
                          <div className="search-btn-box">
                            <button onClick={handleSearch}>Search</button>
                          </div>
                        </div>
                      }
                    />
                  )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Attendence_employee;