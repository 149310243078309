import React, { useEffect, useState, useContext } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { PiUploadBold } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";
import { AppContext } from "../ContextApi";
import {
  ADD_CLAIM_DOCUMENT_API,
  CLAIM_SUBMISSION_DOCUMENTS_LIST_API,
} from "../Constants";
import { noUploadDocIcon } from "../Assets";
import useModal from "../Custom hooks/useModal";
import ClaimExistModal from "../components/ClaimExistModal";
import DataLoadComponent from "../components/DataLoading";
import { FaEye } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";

const Claim_document_form = ({ requestOptions, setCurrentFormIndex }) => {
  const { isOpen, openModal, closeModal, closeDocPopup, openDeletePopup } =
    useModal();
  const [isDoclistView, setDocListView] = useState(false);
  const [documentListData, setDocumentListData] = useState([]);
  const [docPath, setDocpath] = useState("");
  const [docId, setDocId] = useState(null);

  const [files, setFiles] = useState({
    medicineBills: [],
    consultation: [],
    labReport: [],
    paidReceipt: [],
    dischargecard: [],
    indorecasepapers: [],
    hospitalbills: [],
    otherdocuments: [],
    // Add more document types here as needed
  });

  const [DataLoading, setdataLoading] = useState(false);
  const [submissionMsg, setSubmissionMsg] = useState("");
  const [pending, setPending] = useState(true);
  const docTypes = [
    {
      medicineBills: "Medicine Bills",
      consultation: "Consultation",
      labReport: "Lab Report",
      paidReceipt: "Paid Receipt",
      dischargecard: "Discharge Card",
      indorecasepapers: "Indoor Case Papers",
      hospitalbills: "Hospital Bills and Receipts",
      otherdocuments: "Other Documents",
    },
  ];

  const { intimationId, setIntimationId } = useContext(AppContext);

  const handleFileChange = (e, documentType) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => {
      // Filter out files with the same name
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !prevFiles[documentType].find(
            (prevFile) => prevFile.name === newFile.name
          )
      );
      return {
        ...prevFiles,
        [documentType]: [...prevFiles[documentType], ...uniqueNewFiles],
      };
    });
  };

  const documentTypes = Object.keys(files);

  const handleFileDelete = (e, documentType, file, index) => {
    e.preventDefault();
    const updatedDoc = files[documentType].filter(
      (myfile) => myfile.name !== file.name
    );
    setFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: updatedDoc,
    }));
  };

  const claimDocuments = new FormData();
  claimDocuments.append("intimation_id", intimationId);

  const newClaimDocData = {
    data: {
      intimation_id: intimationId,
    },
    document: [],
  };

  useEffect(() => {
    Object.entries(files).forEach(([documentType, fileInputs]) => {
      if (fileInputs.length > 0) {
        fileInputs.forEach((file, index) => {
          const reader = new FileReader();

          reader.onload = () => {
            const documentData = {
              file_name: file.name,
              document_type: documentType,
              title: docTypes[0] ? docTypes[0][documentType] : "",
              file: reader.result,
            };
            newClaimDocData.document.push(documentData);
          };

          reader.readAsDataURL(file);
        });
      }
    });
  }, [files]);

  const columns = [
    {
      name: "Sr No",
      maxWidth: "100px",
      cell: (row, index) => <div className="sr-no">{index + 1}</div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: "Document Name",
      selector: (row) => row.document_type,
    },
    {
      name: "Attachment copy",
      cell: (row) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <a
            href={`${docPath}/${row.file_name}`}
            target="_blank"
            rel="noopener noreferrer"
            className="download-attachment-link"
          >
            {row.file_name}
          </a>
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="delete-kyc-doc-btn"
          onClick={() => handleDeletePopup(row.id)}
        >
          <span>
            <MdDelete />
          </span>
          Delete
        </button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "42px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px", // override the cell padding for head cells
        paddingRight: "15px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px", // override the cell padding for head cells
        paddingRight: "15px",
      },
    },
    0: {
      style: {
        minWidth: "100px",
      },
    },
  };

  const requestOptionsCopy = { ...requestOptions };
  requestOptionsCopy.body = claimDocuments;

 

  const handleDeletePopup = (id) => {
    setDocId(id);
    openDeletePopup();
  };

  const handleView = () => {

    async function getKycListDoc() {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + CLAIM_SUBMISSION_DOCUMENTS_LIST_API,
          requestOptionsCopy
        );
        const res = await response.json();
  
        if (res.status) {
          setDocumentListData(res.data);
          setPending(false);
          setDocpath(res?.path);
        } else {
          setPending(false);
          console.log("Res : False", res);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getKycListDoc();
    setDocListView(!isDoclistView)
  }

  // useEffect(() => {
  //   getKycListDoc();
  // }, [isDoclistView]);

  const onSubmit = (e) => {
    e.preventDefault();

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(newClaimDocData);

    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function save_document_upload() {
      setdataLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + ADD_CLAIM_DOCUMENT_API,
          requestOptionsCopy
        );
        const res = await response.json();

        if (res.status) {
          setSubmissionMsg(res.message);
          setdataLoading(false);
          openModal();
          // getKycListDoc();
          setTimeout(() => {
            setCurrentFormIndex(0);
            setIntimationId(null);
          }, 2000);
        } else {
          setdataLoading(false);
          setSubmissionMsg(res.message);
          openModal();
        }
      } catch (error) {
        console.error(error);
      }
    }
    save_document_upload();
  };

  return (
    <>
      <div className="claim-document-main-wrapper">
        <form id="form-step-3" className="form-step" onSubmit={onSubmit}>
          <div className="form-step3-main-cover-wrapper">
            <h5 className="header-claim-title">Upload Below Documents</h5>

            <p class="upload-doc-info">
              Note : Each tab will accept one single file. Please merge all your
              documents and upload in one file in each tab .
            </p>
          </div>
          <div className="documents-upload-main-cover-wrapper">
            <div className="document-upload-row-cover">
              {/* <div className="view-document-btn d-flex justify-content-center">
                {isDoclistView ? (
                  <>
                    <span>
                      <MdOutlineFileUpload />
                    </span>
                    <button
                      type="button"
                      onClick = {handleView}
                    >
                      Upload Document
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" onClick={() => setDocListView(!isDoclistView)}>
                      <span>
                        <FaEye />
                        View Document
                      </span>
                    </button>
                  </>
                )}
              </div> */}
              {isDoclistView ? (
                <div className="doc-listview-main-wrapper">
                  <DataTable
                    columns={columns}
                    data={documentListData}
                    customStyles={customStyles}
                    pagination
                    progressPending={pending}
                    progressComponent={<DataLoadComponent />}
                  />
                  <h4>DocView</h4>
                </div>
              ) : (
                <>
                  <div className="row d-flex justify-content-center">
                    {documentTypes.length > 0 &&
                      documentTypes.map((documentType, ind) => (
                        <div className="col-lg-5 mb-4" key={documentType}>
                          <div className="upload-document-boxlist-cover">
                            <div className="upload-document-list-header">
                              <h6>{docTypes[0][documentType]}</h6>
                              <div className="upload-document-btn-box">
                                <button>
                                  <span>
                                    <PiUploadBold />
                                  </span>
                                  <input
                                    type="file"
                                    name="claim_upload_file[]"
                                    onChange={(e) =>
                                      handleFileChange(e, documentType)
                                    }
                                    style={{
                                      opacity: 0,
                                      width: 100,
                                      position: "absolute",
                                      left: "-8px",
                                      top: 3,
                                    }}
                                    multiple
                                  />
                                  Upload
                                </button>
                              </div>
                            </div>

                            <div
                              className={
                                `${files[documentType]?.length > 2}`
                                  ? "uploaded-documents-list-box height-style"
                                  : "uploaded-documents-list-box"
                              }
                            >
                              {files[documentType].length > 0 ? (
                                files[documentType].map((file, index) => (
                                  <div
                                    className="uploaded-documents-list"
                                    key={index}
                                  >
                                    <h6>{file.name}</h6>
                                    <button
                                      onClick={(e) =>
                                        handleFileDelete(
                                          e,
                                          documentType,
                                          file,
                                          index
                                        )
                                      }
                                    >
                                      <FaRegCircleXmark />
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <div className="no-document-upload-box d-flex flex-column justify-content-center">
                                  <img
                                    src={noUploadDocIcon}
                                    alt="Doc icon"
                                  ></img>
                                  <h6
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "center",
                                    }}
                                  >
                                    No Added Files
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="submit-btn-box d-flex justify-content-around">
                    <div class="footer-buttons mt-0">
                      <button
                        class="prev"
                        onClick={() => setCurrentFormIndex(1)}
                      >
                        Previous
                      </button>
                    </div>
                    {DataLoading ? (
                      <div className="data-loader d-flex justify-content-center flex-column">
                        <DataLoadComponent />
                        <br></br>
                        <p style={{ fontSize: "14px" }}>please wait..</p>
                      </div>
                    ) : (
                      <input
                        type="submit"
                        className="register-form-btn"
                        value={"Submit"}
                      ></input>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <ClaimExistModal
          DataLoading={DataLoading}
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeDocPopup}
          claimExistMsg={submissionMsg}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default Claim_document_form;
