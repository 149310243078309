import { Routes, Route, Navigate  } from "react-router-dom";
import { useEffect ,useState } from "react";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Networkhospital from "./pages/Networkhospital";
import Escalation_matrix from "./pages/Escalation_matrix";
import Members_covered from "./pages/Members_covered";
import Policydetails from "./pages/Policydetails";
import Downloadforms from "./pages/Downloadforms";
import Claimstatus from "./pages/Claimstatus";
import Mycoverage from "./pages/Mycoverage";
import Intimate_my_claims from "./pages/Intimate_my_claims";
import Leaves from "./pages/Leaves_employee";
import Attendence_employee from "./pages/Attendence_employee";
import Hr_Circular from "./pages/Hr_Circular";
import Wellness_corner from "./pages/Wellness_corner";
import Wellness_Benefits from "./pages/Wellness_Benefits";
import Claim_submission from "./pages/Claim_Submission";

function App() {


  const [isAuthenicated , setIsAuthenicated] = useState(localStorage.getItem("authorizationToken"))


   useEffect(() => {
    fetch(process.env.REACT_APP_API_URL +`/employee/favicon`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch favicon');
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          const faviconLink = document.querySelector("link[rel='icon']");
          faviconLink.href = data.favicon;
        } else {
          console.warn('Favicon URL not found in API response. Using default favicon.');
        }
      })
      .catch(error => console.error('Error fetching favicon:', error));
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login  setIsAuthenicated={setIsAuthenicated}/>} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/network-hospital"
          element={isAuthenicated ? <Networkhospital /> : <Navigate to="/" />}
        />
        <Route
          path="/escalation-matrix"
          element={isAuthenicated ? <Escalation_matrix /> : <Navigate to="/" />}
        />
        <Route
          path="/members-covered"
          element={isAuthenicated ? <Members_covered /> : <Navigate to="/" />}
        />
        <Route
          path="/policy-details"
          element={isAuthenicated ? <Policydetails /> : <Navigate to="/" />}
        />
        <Route
          path="/download-form"
          element={isAuthenicated ? <Downloadforms /> : <Navigate to="/" />}
        />
        <Route
          path="/claim-status"
          element={isAuthenicated ? <Claimstatus /> : <Navigate to="/" />}
        />
        <Route
          path="/mycoverage"
          element={isAuthenicated ? <Mycoverage /> : <Navigate to="/" />}
        />
        <Route
          path="/intimate-myclaims"
          element={
            isAuthenicated ? <Intimate_my_claims /> : <Navigate to="/" />
          }
        />
        <Route
          path="/leave-page"
          element={isAuthenicated ? <Leaves /> : <Navigate to="/" />}
        />
        <Route
          path="/attendence-employee"
          element={
            isAuthenicated ? <Attendence_employee /> : <Navigate to="/" />
          }
        />
        <Route
          path="/hr-circular"
          element={isAuthenicated ? <Hr_Circular /> : <Navigate to="/" />}
        />

        <Route path="/wellness-corner" element={<Wellness_corner />} />
        <Route path="/claim-submission" element={<Claim_submission />} />
        
      </Routes>
    </div>
  );
}

export default App;
