import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { claim_info } from "../Assets";
import LoadingData from "./LoadingAnimation";


const ClaimExistModal = ({ DataLoading , isOpen , openModal ,closeDocPopup , closeModal , claimExistMsg}) => {


  return (
    <>
      <div className="compare-error-main-cover">
        <Modal
          show={isOpen} 
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          className="modal-compare-cover-box"
          centered
        >
          <Modal.Body className="modal-add-to-compare-main-cover">
          {DataLoading == true ? <LoadingData/> : (
            <>
            <div className="close-compare-modal">
              <IoIosCloseCircle onClick={closeModal} />
            </div>
            <div className="claim_exist_modal_cover-wrapper">
                <div className="claim_exist-icon_cover">
                    <img src={claim_info} alt="claim msg"/>
                </div>
                <h5>{claimExistMsg}</h5>
            </div>
            <div className="ok-btn">
              {DataLoading == "" ?  (
                <button onClick={closeDocPopup}>OK</button>
              ) : <button onClick={closeModal}>OK</button> }
              
            </div>
            </>
          )}
            
          </Modal.Body>
        </Modal>
        
      </div>
    </>
  );
};

export default ClaimExistModal;
