import React from "react";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../ContextApi";
import Header from "../components/Header";
import HeaderTabs from "../components/HeaderTabs";
import ErrorDataComponent from "../components/ErrorData";
import CardLoader from "../components/CardLoader";
import NoDataFound from "../components/NoDataFound";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import Wellness_corner from "./Wellness_corner";

import {
  contactimg,
  user_contact_icon,
  phone_contact_icon,
  mail_contact_icon,
  contact_shape_red,
  user_level_bg2,
} from "../Assets/index";
import HeroSection from "../components/HeroSection";

const Escalation_matrix = () => {
  const [escalationdata, setescalationdata] = useState([]);
  const [dataMsg, setDatamsg] = useState("");
  const navigate = useNavigate();
  const {
    activeElementId,
    isLoading,
    setisLoading,
    policytypeidcontext,
    requestOptions,
    setRequestOptions,
  } = useContext(AppContext);
  const [isErrorData, setisErrorData] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        setToken(authorizationToken);
        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    if (token && policytypeidcontext) {
      setisLoading(true);
      fetch(
        process.env.REACT_APP_API_URL +
          `/employee/escalation-matrix-list?policy_type_id=${policytypeidcontext}`,
        requestOptions
      )
        .then((response) => handleTokenInResponse(response))
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setescalationdata(data);
            setisLoading(false);
            setDatamsg(data.message);
          } else {
            setisLoading(false);
            setescalationdata(data);
            setDatamsg(data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching OTP:", error);
          setisErrorData(true);
        });
    }
  }, [policytypeidcontext, token]);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  return (
    <>
      {isErrorData ? (
        <ErrorDataComponent />
      ) : (
        <div className="main-escalation-cover-wrapper">
          <>
            <Header />
            <HeroSection/>
            <div className="claims-tabs-section-cover-wrapper">
              <div className="row">
                <div className="col-lg-4">
                  <div className="claims-header-tabs">
                    <HeaderTabs />
                    <div className="separater"></div>
                    <ClaimsFeatures />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="claims-content-cover-wrapper">
                    <div className="escalation-main-cover-wrapper">
                      <div className="escalation-main-cover">
                        <div className="container">
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 pt-5">
                              <div className="right-banner-box">
                                <div className="network-hospital-heading">
                                  <h3 className="text-center">
                                    Escalation Matrix
                                  </h3>
                                </div>
                                {/* <div className="main-banner-box d-flex justify-content-center">
                                  <img src={contactimg} alt="banner-img" />
                                </div> */}
                              </div>
                            </div>
                            <div className="col-lg-11 mb-5">
                              <div className="row">
                                {isLoading ? (
                                  <CardLoader />
                                ) : escalationdata?.data &&
                                  escalationdata?.data.length > 0 ? (
                                  escalationdata?.data.map((mydata, index) => (
                                    <div className="col-lg-6" key={index}>
                                      <div className="contact-us-details-box">
                                        <div className="contact-us-user">
                                          <div className="user-image">
                                            <img
                                              src={user_contact_icon}
                                              alt="icon"
                                            />
                                          </div>
                                          <div className="user-text">
                                            <p>
                                              Name :{" "}
                                              <span>{mydata.full_name}</span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="contact-us-user">
                                          <div className="phone-image">
                                            <img
                                              src={phone_contact_icon}
                                              alt="icon"
                                            />
                                          </div>
                                          <div className="phone-text">
                                            <p>
                                              Phone :
                                              <span>
                                                {mydata.mobile_number}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="contact-us-user">
                                          <div className="email-image">
                                            <img
                                              src={mail_contact_icon}
                                              alt="icon"
                                            />
                                          </div>
                                          <div className="email-text">
                                            <p>
                                              Email :{" "}
                                              <span>{mydata.email_id}</span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="contact-us-green-box">
                                          {/* <img
                                    src={
                                      mydata.user_level === 1
                                        ? contact_shape_red
                                        : mydata.user_level === 2
                                        ? user_level_bg2
                                        : user_level_bg2
                                    }
                                    alt="bg-shape"
                                  /> */}
                                          <h3
                                            style={{
                                              backgroundColor: "darkgreen",
                                              color: "white",
                                            }}
                                          >
                                            Level {mydata.user_level}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <NoDataFound msg={dataMsg} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      <Wellness_Benefits appLinkToken={appLinkToken} />
      <ToastContainer />
    </>
  );
};

export default Escalation_matrix;
