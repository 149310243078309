import React from "react";
import Header from "../components/Header";
import HeaderTabs from "../components/HeaderTabs";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../ContextApi";
import CardLoader from "../components/CardLoader.js";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import { DataNotFoundIcon } from "../Assets/index";
import ClaimsFeatures from "../components/ClaimsFeatures.js";
import Wellness_Benefits from "./Wellness_Benefits.js";
import HeroSection from "../components/HeroSection.js";
import InsuranceProducts from "../components/InsuranceProducts.js";
import Claim_details_form from "../components/Claim_details_form.js";
import claim_submission_style from "../styles/claim-style.css";
import Claim_document_form from "./Claim_document_form.js";
import { INITIMATE_PATIENT_LIST_API , PINCODE_API } from "../Constants.js";
import HomeClaimKyc from "./Claim_kyc_home.js";
import CustomStepper from "../components/CustomStepper.js";



const Claim_submission = () => {
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const {
    activeElementId,
    requestOptions,
    setRequestOptions,
    tabapiData,
    policytypeidcontext,intimationId ,
    setIntimationId
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [intimateData, setIntimateData] = useState([]);
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))


  // CLaim first Form--------------------------
  const [isDataSaved, setDataSaved] = useState(false);

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        setToken(authorizationToken);

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    if(intimationId === ""){
      setCurrentFormIndex(0)
    }
    else if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const isDataLoading = false;

  useEffect(() => {
    const fetchPatientData = async () => {
      if (token && activeElementId) {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              `${INITIMATE_PATIENT_LIST_API}?policy_id=${activeElementId}&policy_type=${policytypeidcontext}`,
            requestOptions
          );

          const updatedResponse = await handleTokenInResponse(response);
          const res = await updatedResponse.json();

          if (res.success) {
            setIntimateData(res?.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchPatientData();
  }, [activeElementId]);

  const steps = [
    { title: 'Claim Details', onClick: () => setCurrentFormIndex(0) },
    { title: 'Claim Documents', onClick: () => setCurrentFormIndex(1) },
    // { title: 'Kyc Documents', onClick: () => setCurrentFormIndex(1) },
  ];

  return (
    <>
      <Header />
      <HeroSection />
      {tabapiData.length > 0 ? (
        <>
          <div className="claims-tabs-section-cover-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="claims-header-tabs">
                  <HeaderTabs />
                  <div className="separater"></div>
                  <ClaimsFeatures />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="policy-details-main-cover-wrapper">
                  {isDataLoading ? (
                    <CardLoader />
                  ) : (
                    <div className="claims-main-cover-wrappper">
                      <>
                        {/* Claim Submission Process Steps Start */}
                        <div className="tab_content claim-tab_11">
                          <div className="claim-status-outer-cov claim-status-revised-wrapper">
                            <div id="accordion">
                              <div className="stepper_main_wrapper">
                              <CustomStepper
                                steps={steps}
                                activeStep={currentFormIndex}/>
                              {/* <div
                                  className={currentFormIndex == 0 ? "step_claim active-step-claim" : intimationId ? "step_claim"  : "step_claim disable-step-claim"}
                                  id="step_claim"
                                  onClick={() => setCurrentFormIndex(0)}
                                >
                                  <h5>Claim Details</h5>
                                </div>
                                <div
                                  className={currentFormIndex == 1 ? "step_claim active-step-claim" : intimationId ? "step_claim"  : "step_claim disable-step-claim"}
                                  id="step_claim"
                                  onClick={() => setCurrentFormIndex(1)}
                                >
                                  <h5>Kyc Documents</h5>
                                </div>
                                <div
                                  className={currentFormIndex == 2 ? "step_claim active-step-claim" : intimationId ? "step_claim"  : "step_claim disable-step-claim"}
                                  id="step_claim"
                                  onClick={() => setCurrentFormIndex(2)} 
                                >
                                  <h5>Claim Documents</h5>
                                </div> */}
                              </div>
                              {currentFormIndex === 0 ? (
                                <Claim_details_form 
                                      intimateData={intimateData} 
                                      isDataSaved={isDataSaved} 
                                      setDataSaved = {setDataSaved}
                                      setCurrentFormIndex={setCurrentFormIndex}
                                      currentFormIndex={currentFormIndex}
                                      setIntimationId={setIntimationId}
                                />
                              ) : currentFormIndex == 1 ? (
                                
                                <Claim_document_form  setCurrentFormIndex={setCurrentFormIndex} requestOptions={requestOptions} />
                              ) : ""
                              
                        
                              }
                              
                                {/* <HomeClaimKyc setCurrentFormIndex={setCurrentFormIndex} />
                                currentFormIndex == 2 && <Claim_document_form  setCurrentFormIndex={setCurrentFormIndex} requestOptions={requestOptions} /> */}
                              
                            </div>
                          </div>
                        </div>
                        
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="data-not-found-icon">
          <img src={DataNotFoundIcon} alt="data-not-found" />
        </div>
      )}
      <InsuranceProducts />
      <Wellness_Benefits appLinkToken={appLinkToken}/>
    </>
  );
};


export default Claim_submission;
