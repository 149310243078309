import { useContext, useEffect } from "react";
import { AppContext } from "../ContextApi";

const PolicyTypeTab = ({
  policytypename,
  policy_id,
  mykey,
  policy_type_id,
  Tabicon,
  intimateVisibility,
  isTabDisabled,
}) => {
  const {
    activeElementId,
    setActiveElementId,
    setpolicytypeidcontext,
    setGlobalintimateVisiblity,
    defaultactiveId,
    defaultPolicytypeId,
    setdefaultPolicyTypeId,
    featuresType,
    setFeaturesType,
  } = useContext(AppContext);

  const handleTabClick = (
    policy_id,
    policy_type_id,
    intimateVisibility,
    policytypename
  ) => {
    if (activeElementId !== policy_id) {
      cleanup_active();
      setActiveElementId(policy_id);
      setGlobalintimateVisiblity(intimateVisibility);
    }
    setpolicytypeidcontext(policy_type_id);
    setFeaturesType(policytypename);
  };

  function cleanup_active() {
    const tabbox = document.querySelectorAll(".tab-box");
    tabbox.forEach((tab, index) => {
      tab.classList.remove("active-tab-box");
    });
  }



  return (
    <>
      <div
        key={mykey}
        className={
          activeElementId === policy_id
            ? "active-tab-box tab-box"
            : !isTabDisabled
            ? "tab-box disabled-tab"
            : "tab-box"
        }
        onClick={!isTabDisabled ? null : () => handleTabClick(policy_id, policy_type_id, intimateVisibility, policytypename)}
      >
        <div className="policytype-img-box">
          <img src={Tabicon}></img>
        </div>
        <h5 className="m-0">{policytypename}</h5>
      </div>
    </>
  );
};

export default PolicyTypeTab;
