import React, { useState, useContext } from "react";
import { AppContext } from "../ContextApi";

function Pagination({ LinkData, nextPageUrl, prevPageUrl ,setisDataLoading }) {
  const { currentPage, setCurrentPage } = useContext(AppContext);

  const handlePageClick = (apiUrl) => {
    
    if (apiUrl === null) {
      return;
    } else {
      const Url = new URL(apiUrl);
      const pageNumber = Url.searchParams.get("page");
      setCurrentPage(pageNumber);
      window.scrollTo(0, 0);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Render the "Previous" button with the "disabled" attribute if page 1 is active
    if (currentPage === 1) {
      pageNumbers.push(
        <li key={0} className="page_btn_disabled">
          <div dangerouslySetInnerHTML={{ __html: LinkData[0].label }} />
        </li>
      );
    } else {
      pageNumbers.push(
        <li
          key={0}
          onClick={() => handlePageClick(prevPageUrl)}
          className={
            LinkData[0].active === true ? "page_btn_active" : "prevBtn"
          }
        >
          <div className="paginationbtns" dangerouslySetInnerHTML={{ __html: LinkData[0].label }} />
        </li>
      );
    }

    for (let i = 1; i < LinkData.length - 1; i++) {
      pageNumbers.push(
        <li
          key={i}
          onClick={() => handlePageClick(LinkData[i].url)}
          className={
            LinkData[i].active === true ? "page_btn_active" : "page_btn"
          }
        >
          {LinkData[i].label}
        </li>
      );
    }

    // Render the "Next" button with the "disabled" attribute if it is the last page
    if (currentPage === LinkData.length - 1) {
      pageNumbers.push(
        <li key={LinkData.length - 1} className="page_btn_disabled">
          <div
            className="paginationbtn"
            dangerouslySetInnerHTML={{
              __html: LinkData[LinkData.length - 1].label,
            }}
          />
        </li>
      );
    } else {
      pageNumbers.push(
        <li
          key={LinkData.length - 1}
          onClick={() => handlePageClick(nextPageUrl)}
          className={
            LinkData[LinkData.length - 1].active === true
              ? "page_btn_active"
              : "next_btn"
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: LinkData[LinkData.length - 1].label,
            }}
          />
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <ul className="pagination-page-numbers">{renderPageNumbers()}</ul>
    </div>
  );
}

export default Pagination;
