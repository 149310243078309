import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { validateNumberInput, numberToWords } from "../Utils";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../ContextApi";
import { Delete_Icon } from "../Assets/index";
import { NotokenFound } from "../Utils";

const OpdForm = () => {
  const [opdFormData, setopdFromData] = useState([]);
  const { activeElementId, policytypeidcontext ,setRequestOptions ,requestOptions } = useContext(AppContext);
  const [patientsData, setpatientsData] = useState([]);
  const [patientsMsg, setpatientsMsg] = useState("");
  const [EmployeeId, setEmployeeId] = useState(null);
  const [corporateId, setCorporateId] = useState(null);
  const [patientsId, setpatientsId] = useState(null);
  const [formData, setFormData] = useState(new FormData());
  const [ErrorsMsg, setErrorsMsg] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [total_claim_amount, setTotalClaimAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [documentComponents, setDocumentComponents] = useState([
    {
      documentType: "",
      file: null,
    },
  ]);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const opdFormdata = watch();
  
  function handleTokenInResponse(response) {
    if(response.status === 401 || token === ""){
      NotokenFound(navigate)
    }
    else{
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
    
        return updatedResponse;
      } else {
        return response
      }
    }
  }

  const onSubmit = (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formdata.append("claim_document[]", selectedFiles[i]);
    }
    formdata.append("policy_id", opdFormdata.policy_id);
    formdata.append("member_id", opdFormdata.member_id);
    formdata.append("mobile_no", opdFormdata.mobile_no);
    formdata.append("email_id", opdFormdata.email_id);
    formdata.append("date_of_consultation", opdFormdata.date_of_consultation);
    formdata.append("consultation_amount", opdFormdata.consultation_amount);
    formdata.append("medicine_bill_amount", opdFormdata.medicine_bill_amount);
    formdata.append(
      "diagnostic_bill_amount",
      opdFormdata.diagnostic_bill_amount
    );
    formdata.append("total_claim_amount", opdFormdata.total_claim_amount);
    formdata.append("reason_for_claim", opdFormdata.reason_for_claim);
    formdata.append("hospital_name", opdFormdata.hospital_name);
    formdata.append("hospital_address", opdFormdata.hospital_address);
    formdata.append("corporate_id", opdFormdata.corporate_id);
    formdata.append("employee_details_id", opdFormdata.employee_details_id);
    formdata.append("document_type[]", opdFormdata.document_type);
    formdata.append("source", 0);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formdata;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    fetch( process.env.REACT_APP_API_URL +`/employee/save-opd-claim-intimation`, requestOptionsCopy)
      .then((response) => handleTokenInResponse(response))
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setIsLoading(false);
          setSelectedFileNames([]);
          setSelectedFiles([]);
          reset();
        } else {
          setIsLoading(false);
          setErrorsMsg(res.errors);
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
      });
  };

  useEffect(() => {
    if (token && activeElementId) {
      fetch( process.env.REACT_APP_API_URL +
        `/employee/intimate-claims-patients-list?policy_id=${activeElementId}&policy_type=${policytypeidcontext}`,
        requestOptions
      )
      .then((response) => handleTokenInResponse(response))
      .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setEmployeeId(res.data?.employee_details?.emp_id);
            setCorporateId(res.data?.employee_details?.corporate_id);
            setpatientsMsg(res.message);
            setpatientsData(res?.data?.patients);
            setpatientsId(res?.data?.id);
            setValue("policy_id", activeElementId);
            setValue("employee_details_id", res.data?.employee_details?.emp_id);
            setValue("corporate_id", res.data?.employee_details?.corporate_id);
          } else {
            setpatientsMsg(res.message);
          }
          // data.success ? setIsVerified(true) : setpatientsMsg(data.message);
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error);
        });
    }
  }, [activeElementId , token]);

  const claim_document = watch()?.claim_document;
  const addDocumentComponent = () => {
    const updatedComponents = [
      ...documentComponents,
      {
        documentType: "",
        file: null,
      },
    ];
    setDocumentComponents(updatedComponents);
  };

  const deleteDocumentComponent = (index) => {
    const document_type = watch()?.document_type;

    setValue(
      "document_type",
      document_type.filter((_, ind) => ind !== index)
    );

    const updatedDocuments = documentComponents.filter(
      (_, ind) => ind !== index
    );
    setDocumentComponents(updatedDocuments);

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1); // Remove the file at the specified index
      return updatedFiles;
    });

    setSelectedFileNames((prevSelectedFileNames) => {
      const updatedFileNames = [...prevSelectedFileNames];
      updatedFileNames.splice(index, 1);
      return updatedFileNames;
    });
  };

  const handleFileChange = (index, e) => {
    const files = e.target.files;

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles[index] = files[0];
      return updatedFiles;
    });

    setSelectedFileNames((prevSelectedFileNames) => {
      const updatedFileNames = [...prevSelectedFileNames];
      updatedFileNames[index] = files[0] ? files[0].name : "";
      return updatedFileNames;
    });
  };

  useEffect(() => {
    if (token && activeElementId) {
      fetch( process.env.REACT_APP_API_URL +
        `/employee/intimate-claims-patients-list?policy_id=${activeElementId}&policy_type=${policytypeidcontext}`,
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.success) {
            setpatientsMsg(res.message);
            setpatientsData(res?.data?.patients);
          } else {
            setpatientsMsg(res.message);
          }
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error);
        });
    }
  }, [activeElementId]);

  useEffect(() =>{
    if(!token ){
      NotokenFound(navigate)
    }
  },[])

  useEffect(() => {
    setValue("claim_document", selectedFiles);
  }, [selectedFiles]);

  function calculateTotalClaimAmount() {
    const consultation_amount =
      parseFloat(opdFormdata.consultation_amount) || 0;
    const medicine_bill_amount =
      parseFloat(opdFormdata.medicine_bill_amount) || 0;
    const diagnosticBillAmount =
      parseFloat(opdFormdata.diagnostic_bill_amount) || 0;

    const totalClaimAmount =
      consultation_amount + medicine_bill_amount + diagnosticBillAmount;
      opdFormdata.total_claim_amount = +totalClaimAmount;
    return totalClaimAmount;
  }

  function updateTotalClaimAmount() {
    const totalClaimAmount = calculateTotalClaimAmount();
    opdFormdata.total_claim_amount = +totalClaimAmount;
  }

  return (
    <>
      <div className="ipdform-cover-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Patient Name <span className="required-input">*</span>
                  </label>
                  <select
                    {...register("member_id", {
                      required: "Patient Name is required",
                    })}
                  >
                    <option value="">Select a patient</option>
                    {patientsData &&
                      patientsData.map((option) => {
                        return <option value={option.id}>{option.name}</option>;
                      })}
                  </select>

                  {errors.member_id && (
                    <small className="text-danger">
                      Patient Name is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Mobile Number <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    name="reason_for_claim"
                    className="form-control"
                    onInput={(e) => validateNumberInput(e)}
                    id="reason_for_claim"
                    maxLength={10}
                    placeholder="Enter Mobile number"
                    {...register("mobile_no", {
                      required: "Mobile number is required",
                      pattern: {
                        value:
                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                        message: "Invalid phone no",
                      },
                    })}
                  />
                  <input
                    type="hidden"
                    {...register("policy_id")}
                    value={activeElementId}
                  />
                  <input
                    type="hidden"
                    {...register("employee_details_id")}
                    value={EmployeeId}
                  />
                  <input
                    type="hidden"
                    {...register("corporate_id")}
                    value={corporateId}
                  />
                  {errors.mobile_no && (
                    <small className="text-danger">
                      {errors.mobile_no.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email Id <span className="required-input">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id=""
                    placeholder="Enter email id"
                    {...register("email_id", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email_id && (
                    <small className="text-danger">
                      {errors.email_id.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Date of consultation
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    aria-describedby="emailHelp"
                    {...register("date_of_consultation", {
                      required: "Date is required",
                    })}
                  />
                  {errors.date_of_consultation && (
                    <small className="text-danger">
                      {errors.date_of_consultation.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Consulatation Amount{" "}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    onInput={(e) => validateNumberInput(e)}
                    placeholder="Enter consultation amount"
                    {...register("consultation_amount", {
                      required: "Amount is required",
                      pattern: {
                        value: /^(0|[1-9]\d*)$/,
                        message: "invalid input amount",
                      },
                    })}
                    onKeyUp={(e) => updateTotalClaimAmount()}
                  />
                  {errors.consultation_amount && (
                    <small className="text-danger">
                      {errors.consultation_amount.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Medicine Bill Amount{" "}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    onInput={(e) => validateNumberInput(e)}
                    aria-describedby="emailHelp"
                    placeholder="Enter medicine bill amount"
                    {...register("medicine_bill_amount", {
                      required: "Medicine bill amount is required",
                      pattern: {
                        value: /^(0|[1-9]\d*)$/,
                        message: "invalid input amount",
                      },
                    })}
                    onKeyUp={(e) => updateTotalClaimAmount()}
                  />
                  {errors.medicine_bill_amount && (
                    <small className="text-danger">
                      {errors.medicine_bill_amount.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Diagnostics Bill Amount{" "}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    onInput={(e) => validateNumberInput(e)}
                    aria-describedby="emailHelp"
                    placeholder="Enter Diagnostics Bill Amount"
                    {...register("diagnostic_bill_amount", {
                      required: "Diagnostics bill amount is required",
                      pattern: {
                        value: /^(0|[1-9]\d*)$/,
                        message: "invalid input amount",
                      },
                    })}
                    onKeyUp={(e) => updateTotalClaimAmount()}
                  />
                  {errors.diagnostic_bill_amount && (
                    <small className="text-danger">
                      {errors.diagnostic_bill_amount.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Total Claim Amount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="emailHelp"
                    placeholder="Enter Total claim amount"
                    {...register("total_claim_amount")}
                    value={calculateTotalClaimAmount()}
                    readOnly
                    // onInput={(e) => validateNumberInput(e)}
                  />
                  <small className="words_text">
                    {numberToWords(calculateTotalClaimAmount())}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Reason for claim <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="emailHelp"
                    placeholder="Enter reason for claim"
                    {...register("reason_for_claim", {
                      required: "reason is required",
                    })}
                  />
                  {errors.reasonclaim && (
                    <small className="text-danger">
                      {errors.reasonclaim.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name of hospital <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="emailHelp"
                    placeholder="Enter Hospital name"
                    {...register("hospital_name", {
                      required: "Hospital name  is required",
                    })}
                  />
                  {errors.hospital_name && (
                    <small className="text-danger">
                      {errors.hospital_name.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Hospital Address <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="emailHelp"
                    placeholder="Enter Hospital Address"
                    {...register("hospital_address", {
                      required: "Address  is required",
                    })}
                  />
                  {errors.hospital_address && (
                    <small className="text-danger">
                      {errors.hospital_address.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            {documentComponents.map((component, index) => (
              <div key={uuidv4()}>
                <div className="my-document-wrapper">
                  <div
                    className="row mb-3 d-flex align-items-center"
                    id={`document_[${index}]`}
                  >
                    <div className="col-lg-4">
                      <div className="form-input-box mb-3">
                        <div className="form-input">
                          <label
                            htmlFor={`document_type[${index}]`}
                            className="form-label"
                          >
                            Document Type{" "}
                            <span className="required-input">*</span>
                          </label>
                          <Controller
                            name={`document_type[${index}]`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <select {...field}>
                                <option value="">Select Document type</option>
                                <option value="prescription">
                                  Prescription
                                </option>
                                <option value="medicinebills">
                                  Medicine Bills
                                </option>
                                <option value="labreport">Lab Report</option>
                                <option value="paidreceipt">
                                  Paid Receipt
                                </option>
                                <option value="cancelcheque">
                                  Cancel Cheque
                                </option>
                                <option value="other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-input-box mb-3 upload-document-box">
                        <div className="form-input">
                          <label
                            htmlFor={`claim_document[${index}]`}
                            className="form-label upload-label"
                          >
                            Upload Document{" "}
                            <small className="file_type">
                              (Only PDF And Images Allowed)
                            </small>
                          </label>
                          <input
                            name={`claim_document[${index}]`}
                            type="file"
                            accept=".pdf,.img"
                            id={`file[${index}]`}
                            onChange={(e) => handleFileChange(index, e)}
                          />
                          <div>{selectedFileNames[index]}</div>
                        </div>
                      </div>
                    </div>
                    {index === 0 && (
                      <div className="col-lg-2">
                        <button
                          type="button"
                          className="AddnewDocbutton"
                          onClick={addDocumentComponent}
                        >
                          <span className="button__text">Add New</span>
                          <span className="button__icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              stroke="currentColor"
                              height={24}
                              fill="none"
                              className="svg"
                            >
                              <line y2={19} y1={5} x2={12} x1={12} />
                              <line y2={12} y1={12} x2={19} x1={5} />
                            </svg>
                          </span>
                        </button>
                      </div>
                    )}
                    {index !== 0 && (
                      <div className="col-lg-1">
                        <div className="delete-btn-box">
                          <button
                            type="button"
                            onClick={() => deleteDocumentComponent(index)}
                          >
                            <img src={Delete_Icon}></img>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="submit-btn-box">
            <input
              disabled={isLoading}
              type="submit"
              className="register-form-btn mt-4 mb-3"
              value={isLoading ? "please wait..." : "Submit"}
            ></input>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default OpdForm;
