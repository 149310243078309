import PolicyTypeTab from "./PolicyTypeTab";
import { useContext, useEffect } from "react";
import { AppContext } from "../ContextApi";
import TabLoader from "../components/TabLoader";

const HeaderTabs = () => {
  const {
    istabLoading ,tabapiData
  } = useContext(AppContext);


  return (
    <>
      <div className="policy-type-tab-main-cover">
        {istabLoading ? (
          <div className="tab_loadermain-wrapper">
            <TabLoader />
          </div>
        ) : (
          tabapiData.map((tabdata , ind) => (

            <PolicyTypeTab
              mykey={ind}
              Tabicon = {tabdata.logo}
              fullName = {tabdata.full_name}
              policytypename={tabdata.policy_type_name}
              policy_id={tabdata.policy_id}
              policy_type_id={tabdata.policy_type_id}
              intimateClaimvisibiltyData = {tabdata.intimate_claim_visibility}
              isTabDisabled={true}
            />
          ))
        )}
      </div>
    </>
  );
};

export default HeaderTabs;
