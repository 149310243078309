import React from 'react';
import ContentLoader from 'react-content-loader';

const HospitalLoader = props => (
  <ContentLoader
    width={400}
    height={200}
    viewBox="0 0 700 350"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="4" y="8" rx="25" ry="25" width="8" height="317" />
    <rect x="7" y="317" rx="25" ry="25" width="669" height="8" />
    <rect x="669" y="9" rx="25" ry="25" width="7" height="313" />
    <rect x="5" y="8" rx="25" ry="25" width="669" height="7" />
    <rect x="145" y="55" rx="10" ry="10" width="400" height="25" /> 
    <circle cx="80" cy="70" r="35" /> 
    <rect x="145" y="155" rx="10" ry="10" width="400" height="25" />  
    <circle cx="80" cy="165" r="35" /> 
    <rect x="145" y="235" rx="10" ry="10" width="400" height="25" />  
    <circle cx="80" cy="270" r="35" />  
  </ContentLoader>
);

export default HospitalLoader;
