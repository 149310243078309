import { useState } from "react";

const useModal = () =>{
    const[isOpen , setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [modalFlag , setModalFlag] = useState(false);
    const [isDeleteModalOpen , setModalOpen] = useState(false);



    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false);
        window.location.reload();
      };

    const closeKycForm = () => {
        setModalFlag(false)
    }

    const closeModalKyc = () =>{
        setIsOpen(false);
    }

    const openDeletePopup = () =>{
        setModalOpen(true)
    }

    const closeDeletePopup = () =>{
        setModalOpen(false)
    }

    const closeDocPopup = () =>{
        setIsOpen(false)
    }

    return {
        isOpen,openModal ,isDeleteModalOpen,openDeletePopup,closeDeletePopup, closeModal , closeKycForm , closeDocPopup , modalFlag , setModalFlag,closeModalKyc
    }
}

export default useModal