import React, { useState, useContext, useEffect } from "react";
import HeaderTabs from "../components/HeaderTabs";
import Accordion from "react-bootstrap/Accordion";
import { AppContext } from "../ContextApi";
import { coverageheadericon, infobutton } from "../Assets/index";
import Header from "../components/Header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { searchicon } from "../Assets/index";
import CardLoader from "../components/CardLoader";
import NoDataFound from "../components/NoDataFound";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";

const Mycoverage = () => {
  const [myCoverageData, setmyCoverageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isErrorData, setisErrorData] = useState(false);
  const [firstGroup, setfirstGroup] = useState(null);
  const [secondGroupData, setsecondGroupData] = useState(null);
  const [itemsPerGroup, setitemsPerGroup] = useState(null);
  const [datamsg, setDataMsg] = useState("");
  const {
    activeElementId,
    constantsApiData,
    requestOptions,
    setRequestOptions,
  } = useContext(AppContext);
  const [isloadingData, setisLoadingData] = useState(true);
  const [openItem, setOpenItem] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [unAuthorized, setUnAuthroized] = useState(null);
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))

  const [searchText, setSearchText] = useState("");
  const debounceTimeout = 2000;
  const navigate = useNavigate();

  const imgPath = constantsApiData?.path?.EMPLOYEE_IMAGE_PATH;

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      // NotokenFound(navigate)
      setUnAuthroized(false);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken);
        setUnAuthroized(true);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const activeLoader = () => {
    setisLoadingData(true);
  };

  async function FetchMycoverageData() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/employee/my-coverage-list/?policy_id=${activeElementId}`,
        requestOptions
      );
      const tokenizedResponse = await handleTokenInResponse(response);
      const data = await tokenizedResponse.json();

      if (data.success) {
        setisErrorData(false);
        setisLoadingData(false);
        setmyCoverageData(data.data);
        setisLoading(false);
      } else {
        setisLoadingData(false);
        setmyCoverageData(data.data);
        setDataMsg(data.message);
        setisErrorData(true);
        setisLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (token && activeElementId) {
      let timer;
      const fetchData = async (query) => {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              `/employee/my-coverage-list/?policy_id=${activeElementId}&category_name=${query}`,
            requestOptions
          );
          const tokenResponse = await handleTokenInResponse(response);
          const res = await tokenResponse.json();
          if (res.success) {
            setmyCoverageData(res.data);
            // console.log(res.data)
            setisLoadingData(false);
            setisErrorData(false);
          } else {
            // setmyCoverageData([]);
            setisLoadingData(false);
            setisErrorData(true);
            setDataMsg(res.message);
          }
        } catch (error) {
          console.error(error);
          setmyCoverageData([]);
        }
      };

      clearTimeout(timer);
      if (searchText) {
        timer = setTimeout(() => {
          fetchData(searchText);
        }, debounceTimeout);
      } else {
        setisErrorData(false);
        setisLoadingData(false);
        FetchMycoverageData();
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchText]);

  useEffect(() => {
    setisLoadingData(true);
    async function fetchData() {
      if (token) {
        try {
          await FetchMycoverageData();
        } catch (error) {
          console.error(error);
        } finally {
          setisLoadingData(false);
        }
      }
    }
    fetchData();
  }, [activeElementId, token]);

  useEffect(() => {
    if (!token || unAuthorized) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  useEffect(() => {
    if (filteredData.length > 0) {
      setitemsPerGroup(Math.ceil(filteredData.length / 2));
      setfirstGroup(filteredData.slice(0, itemsPerGroup));
      setsecondGroupData(
        filteredData.slice(itemsPerGroup, filteredData.length)
      );
    }
  }, [filteredData, itemsPerGroup]);

  useEffect(() => {
    const newItemsPerGroup = Math.ceil(myCoverageData.length / 2);
    const newFirstGroup = myCoverageData.slice(0, newItemsPerGroup);
    const newSecondGroupData = myCoverageData.slice(
      newItemsPerGroup,
      myCoverageData.length
    );

    setitemsPerGroup(newItemsPerGroup);
    setfirstGroup(newFirstGroup);
    setsecondGroupData(newSecondGroupData);
  }, [myCoverageData]);

  // Accordion Functionality

  const handleAccordionClick = (eventKey) => {
    if (openItem === eventKey) {
      setOpenItem(null);
    } else {
      setOpenItem(eventKey);
    }
  };

  return (
    <>
      <div className="mycoverage-main-cover">
        <Header />
        <HeroSection />
        <div className="mycoverage-main-wrapper-cover">
          <div className="my-coverage-header-box">
            <div className="claims-tabs-section-cover-wrapper">
              <div className="row">
                <div className="col-lg-4">
                  <div className="claims-header-tabs">
                    <HeaderTabs />
                    <div className="separater"></div>
                    <ClaimsFeatures />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="claims-content-cover-wrapper mycoverage-content-cover">
                    <div className="search-bar-box">
                      <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchInputChange}
                        placeholder="Search..."
                        onInput={activeLoader}
                      />
                      {/* <div className="search-icon-box">
                        <img src={searchicon}></img>
                      </div> */}
                    </div>

                    <div className="my-coverage-main-content-cover-wrapper">
                      <div className="container">
                        {isloadingData ? (
                          <CardLoader />
                        ) : (
                          <div className="my-coverage-content">
                            <Accordion defaultActiveKey={openItem}>
                              <div className="row d-flex justify-content-center">
                                {firstGroup &&
                                secondGroupData &&
                                !isErrorData ? (
                                  <>
                                    <div className="col-lg-6">
                                      <div className="row">
                                        {firstGroup?.map((elem, ind) => {
                                          const eventkey = ind + 1;
                                          const paramdata = [
                                            elem.params.map((myparamdata) => {
                                              return myparamdata;
                                            }),
                                          ];
                                          return (
                                            <div
                                              className="col-lg-12"
                                              key={ind}
                                            >
                                              <Accordion.Item
                                                eventKey={eventkey}
                                                onClick={() =>
                                                  handleAccordionClick(ind)
                                                }
                                              >
                                                <Accordion.Header>
                                                  <div className="my-coverage-accordion-header-box">
                                                    <div className="my-coverage-accordion-header">
                                                      <div className="coverage-accordian-icon-box">
                                                        <img
                                                          src={`${imgPath}/${elem?.category_logo}`}
                                                          alt={
                                                            elem?.category_logo
                                                          }
                                                        />
                                                      </div>
                                                      <h5>
                                                        {elem.category_name}
                                                      </h5>
                                                    </div>
                                                    <div className="main-description">
                                                      <p className="my-coverage-small-title">
                                                        {paramdata
                                                          .map(
                                                            (
                                                              params,
                                                              outerIndex
                                                            ) =>
                                                              params
                                                                .map(
                                                                  (mylabel) =>
                                                                    Array.isArray(
                                                                      mylabel?.rfq_label
                                                                    )
                                                                      ? mylabel.rfq_label.join(
                                                                          ", "
                                                                        )
                                                                      : mylabel.rfq_label
                                                                )
                                                                .join(", ")
                                                          )
                                                          .join(", ").length >
                                                        30
                                                          ? `${paramdata
                                                              .map((params) =>
                                                                params
                                                                  .map(
                                                                    (mylabel) =>
                                                                      Array.isArray(
                                                                        mylabel?.rfq_label
                                                                      )
                                                                        ? mylabel.rfq_label.join(
                                                                            ", "
                                                                          )
                                                                        : mylabel.rfq_label
                                                                  )
                                                                  .join(", ")
                                                                  .substring(
                                                                    0,
                                                                    30
                                                                  )
                                                              )
                                                              .join(", ")}...`
                                                          : paramdata
                                                              .map((params) =>
                                                                params
                                                                  .map(
                                                                    (mylabel) =>
                                                                      Array.isArray(
                                                                        mylabel?.rfq_label
                                                                      )
                                                                        ? mylabel.rfq_label.join(
                                                                            ", "
                                                                          )
                                                                        : mylabel.rfq_label
                                                                  )
                                                                  .join(", ")
                                                              )
                                                              .join(", ")}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="claim-details-list-cover">
                                                    {paramdata.map(
                                                      (params, outerIndex) => {
                                                        return (
                                                          <div key={outerIndex}>
                                                            {params.map(
                                                              (
                                                                myparams,
                                                                innerIndex
                                                              ) => (
                                                                <div className="claims-details-list">
                                                                  <div className="claims-list-header">
                                                                    <h5>
                                                                      {myparams
                                                                        .rfq_label
                                                                        .length >
                                                                      30
                                                                        ? myparams.rfq_label +
                                                                          "..."
                                                                        : myparams.rfq_label}
                                                                    </h5>
                                                                    {myparams.rfq_description &&
                                                                    myparams.rfq_description.trim() !==
                                                                      "" ? (
                                                                      <div className="list-info-btn-box">
                                                                        <OverlayTrigger
                                                                          placement="top"
                                                                          overlay={
                                                                            <Tooltip>
                                                                              {
                                                                                myparams.rfq_description
                                                                              }
                                                                            </Tooltip>
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={
                                                                              infobutton
                                                                            }
                                                                            alt=""
                                                                          />
                                                                        </OverlayTrigger>
                                                                      </div>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </div>
                                                                  <div className="claims-list-details">
                                                                    <p>
                                                                      {
                                                                        myparams?.rfq_value
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="row">
                                        {secondGroupData?.map((elem, ind) => {
                                          const eventkey =
                                            ind + 1 + firstGroup?.length;
                                          const paramdata = [
                                            elem.params.map((myparamdata) => {
                                              return myparamdata;
                                            }),
                                          ];
                                          return (
                                            <div
                                              className="col-lg-12"
                                              key={ind}
                                            >
                                              <Accordion.Item
                                                eventKey={eventkey}
                                                onClick={() =>
                                                  handleAccordionClick(ind)
                                                }
                                              >
                                                <Accordion.Header>
                                                  <div className="my-coverage-accordion-header-box">
                                                    <div className="my-coverage-accordion-header">
                                                      <div className="coverage-accordian-icon-box">
                                                        <img
                                                          src={`${imgPath}/${elem?.category_logo}`}
                                                          alt={
                                                            elem?.category_logo
                                                          }
                                                        />
                                                      </div>
                                                      <h5>
                                                        {elem.category_name}
                                                      </h5>
                                                    </div>
                                                    <div className="main-description">
                                                      <p className="my-coverage-small-title">
                                                        {paramdata
                                                          .map(
                                                            (
                                                              params,
                                                              outerIndex
                                                            ) =>
                                                              params
                                                                .map(
                                                                  (mylabel) =>
                                                                    Array.isArray(
                                                                      mylabel?.rfq_label
                                                                    )
                                                                      ? mylabel.rfq_label.join(
                                                                          ", "
                                                                        )
                                                                      : mylabel.rfq_label
                                                                )
                                                                .join(", ")
                                                          )
                                                          .join(", ").length >
                                                        30
                                                          ? `${paramdata
                                                              .map((params) =>
                                                                params
                                                                  .map(
                                                                    (mylabel) =>
                                                                      Array.isArray(
                                                                        mylabel?.rfq_label
                                                                      )
                                                                        ? mylabel.rfq_label.join(
                                                                            ", "
                                                                          )
                                                                        : mylabel.rfq_label
                                                                  )
                                                                  .join(", ")
                                                                  .substring(
                                                                    0,
                                                                    30
                                                                  )
                                                              )
                                                              .join(", ")}...`
                                                          : paramdata
                                                              .map((params) =>
                                                                params
                                                                  .map(
                                                                    (mylabel) =>
                                                                      Array.isArray(
                                                                        mylabel?.rfq_label
                                                                      )
                                                                        ? mylabel.rfq_label.join(
                                                                            ", "
                                                                          )
                                                                        : mylabel.rfq_label
                                                                  )
                                                                  .join(", ")
                                                              )
                                                              .join(", ")}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="claim-details-list-cover">
                                                    {paramdata.map(
                                                      (params, outerIndex) => {
                                                        return (
                                                          <div key={outerIndex}>
                                                            {params.map(
                                                              (
                                                                myparams,
                                                                innerIndex
                                                              ) => (
                                                                <div className="claims-details-list">
                                                                  <div className="claims-list-header">
                                                                  
                                                                    <h5>
                                                                      
                                                                      {
                                                                        myparams.rfq_label === "Add Remarks" && ""
                                                                      }
                                                                      
                                                                    </h5>
                                                                    {myparams.rfq_description &&
                                                                    myparams.rfq_description.trim() !==
                                                                      "" ? (
                                                                      <div className="list-info-btn-box">
                                                                        <OverlayTrigger
                                                                          placement="top"
                                                                          overlay={
                                                                            <Tooltip>
                                                                              {
                                                                                myparams.rfq_description
                                                                              }
                                                                            </Tooltip>
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={
                                                                              infobutton
                                                                            }
                                                                            alt=""
                                                                          />
                                                                        </OverlayTrigger>
                                                                      </div>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </div>
                                                                  <div className="claims-list-details">
                                                                    {/<\/?[a-z][\s\S]*>/i.test(
                                                                      myparams?.rfq_value
                                                                    ) ? (
                                                                      <p
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            myparams?.rfq_value,
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      <p>
                                                                        {
                                                                          myparams?.rfq_value
                                                                        }
                                                                      </p>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <NoDataFound msg={datamsg} />
                                )}
                              </div>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Wellness_Benefits  appLinkToken={appLinkToken}/>
      <ToastContainer />
    </>
  );
};

export default Mycoverage;
