import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { validateNumberInput, handleTokenInResponse } from "../Utils";
import { AppContext } from "../ContextApi";
import ClaimExistModal from "./ClaimExistModal";
import {SAVE_CLAIM_SUBMISSION_API , PINCODE_API , CHECK_CLAIM_EXIST_API , CLAIM_SUBMISSION_BY_INTIMATION_ID_API} from "../Constants";
import useModal from "../Custom hooks/useModal";
import ClaimSubmisionModal from "../components/ClaimSubmissionModal.js";



const Claim_details_form = ({ intimateData , setCurrentFormIndex, setDataSaved }) => {
  const [existingData , setExistingData] = useState(null);
  const [resStatus , setResStatus] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm({
  });

  
 

  const pincode = watch("pincode");
  const hospitalization_date = watch("hospitalization_date");
  const member_id = watch("member_id");
  const employee_details_id = watch("employee_details_id");
  const corporate_id = watch("corporate_id");
  const [pincodeData, setpincodeData] = useState([]);
  const [submissionMsg , setSubmissionMsg] = useState("");
 
 
  const [claimExistMsg , setClaimExistMsg] = useState("");
  const { isOpen, openModal, closeModal , toggleReload  ,modalFlag,setModalFlag, closeKycForm} = useModal();
  const [isLoading , setLoading] = useState(false);
  const {
    activeElementId,
    policytypeidcontext,
    requestOptions,setIntimationId,
    setRequestOptions,
    intimationId
  } = useContext(AppContext);

  const [errorMessages , setErrorMessages] = useState({})
  const policy_id = watch("policy_id");
  const source = watch("source");
  const remark = watch("remark");
  
  
  // Fetching Pincode Api ========
  async function fetchData() {
    const response = await fetch(`${PINCODE_API}` + pincode);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    if (data[0].Status === "Success") {
      const pincodeData = data[0].PostOffice[0];
      setValue("city", pincodeData.District);
      setValue("state", pincodeData.State);
      setpincodeData(pincodeData);
    } else {
      setpincodeData([]);
      setValue("city", "");
      setValue("state", "");
    }
  }

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      fetchData();
      setValue("city", pincodeData.District);
      setValue("state", pincodeData.State);
    } else {
      setpincodeData([]);
    }
  }, [pincode]);

  useEffect(() => {
    const check_claim_exist = async () => {
      const check_claim_data = {
        corporate_id: corporate_id,
        policy_no_id: activeElementId,
        employee_id: employee_details_id,
        emp_member_id: member_id,
        hospitalization_date: hospitalization_date,
      };

      const requestOptionsCopy = { ...requestOptions };
      requestOptionsCopy.method = "POST";
      requestOptionsCopy.body = JSON.stringify(check_claim_data);

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + CHECK_CLAIM_EXIST_API,
          requestOptionsCopy
        );
        const updatedResponse = await handleTokenInResponse(response);
        const res = await updatedResponse.json();

        if (res.status) {
          setClaimExistMsg(res.message)
          openModal(true)
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!existingData && hospitalization_date) {
      check_claim_exist();
    }
  }, [hospitalization_date]);

  const onSubmit = (data) => {
    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";


    const upDatedData = {
      intimate_claim_id : intimationId,
      remark : remark
    }
    
    data.policy_id = activeElementId;

    if(existingData !== null){
      requestOptionsCopy.body = JSON.stringify(upDatedData);
    }else {
      requestOptionsCopy.body = JSON.stringify(data);
      data.source = 0
    }

    async function save_ipd_claim() {
      setLoading(true)
      setModalFlag(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + SAVE_CLAIM_SUBMISSION_API,
          requestOptionsCopy
        );
        const updatedResponse = await handleTokenInResponse(response);
        const res = await updatedResponse.json();

        if (res.status) {
          setResStatus(res.status)
          setIntimationId(res?.claim_intimation_id)
          setDataSaved(true);
          setLoading(false)
          setSubmissionMsg(res.message);
          // reset();
        } else {
          setResStatus(res.status)
          setErrorMessages(res.errors);
          setSubmissionMsg(res.message)
          setDataSaved(false);
          setLoading(false)
        }
      } catch (error) {
        setDataSaved(false);
        console.error(error);
      }
    }    
    save_ipd_claim();
  };
  

  useEffect(() => {
    if(intimateData){
      setValue("employee_details_id", intimateData?.employee_details?.emp_id);
      setValue("corporate_id", intimateData?.employee_details?.corporate_id);
    }

  }, [intimateData]);


  useEffect(() =>{
    if(existingData){
      setValue("employee_details_id", existingData?.employee_details_id);
      setValue("corporate_id", existingData?.corporate_id);
      setValue("policy_id", existingData?.policy_id);
      setValue("member_id", existingData?.member_id);
      setValue("reason_for_claim", existingData?.reason_for_claim);
      setValue("report_amount", existingData?.report_amount);
      setValue("hospitalization_date", existingData?.hospitalization_date);
      setValue("discharge_date", existingData?.discharge_date);
      setValue("hospital_name", existingData?.hospital_name);
      setValue("hospital_address", existingData?.hospital_address);
      setValue("pincode", existingData?.pincode);
      setValue("city", existingData?.city);
      setValue("state", existingData?.state);
      setValue("claim_type", existingData?.claim_type);
      setValue("remark", existingData?.remark);
    }
  },[existingData , setValue])

  useEffect(() =>{

    const data = {intimation_id : intimationId}

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(data);
    
    async function get_intimation_claim() {
  
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + CLAIM_SUBMISSION_BY_INTIMATION_ID_API,
          requestOptionsCopy
        );
        const updatedResponse = await handleTokenInResponse(response);
        const res = await updatedResponse.json();

        if (res.status) {
          setExistingData(res?.claim_submission_details);
          setIntimationId(res.intimation_id);
        }
      } catch (error) {
        
        console.error(error);
      }
    }
    if(intimationId) {
      get_intimation_claim();
    }
  },[intimationId])

  const hospitalizationDate = watch("hospitalization_date");
  const dischargeDate = watch("discharge_date");

  const validateDischargeDate = (dischargeDate) => {
    if (hospitalizationDate && dischargeDate) {
      const hospitalizationDateObj = new Date(hospitalizationDate);
      const dischargeDateObj = new Date(dischargeDate);

      if (dischargeDateObj < hospitalizationDateObj) {
        setError("discharge_date", {
          type: "validate",
          message: "Discharge date cannot be less then  hospitalization date",
        });
      } else {
        clearErrors("discharge_date");
      }
    }
  };


  return (
    <>
      <div className="claim-details-main-cover-wrapper">
        <form
          id="form-step-1"
          className="form-step active"
          onSubmit={handleSubmit(onSubmit)}
        >
        <input
                type="hidden"
                className="test"
                {...register("policy_id")}
                
              />
              <input
                type="hidden"
                className="test"
                {...register("employee_details_id")}
                // value={intimateData?.employee_details?.emp_id}
              />
              <input
                type="hidden"
                className="test"
                {...register("corporate_id")}
                // value={intimateData?.employee_details?.corporate_id}
              />
          <div className="row claim-intimate-forms-cover d-flex justify-content-center">
            <div className="col-md-5 mb-3">
              <label htmlFor="patientName">
                Patient Name
                <span className="error"> * </span>
              </label>

              <select
                className="form-control"
                {...register("member_id", {
                  required: "Patient Name is required",
                })}
                disabled={existingData?.member_id ? true : false}
              >
                <option value="">Select a patient</option>
                {intimateData?.patients &&
                  intimateData?.patients.map((option) => {
                    return <option value={option.id}>{option.name}</option>;
                  })}
              </select>
              {errors.member_id && (
                <small className="text-danger">please select patient</small>
              )}
            </div>
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label>
                  Hospitalization Date
                  <span className="error"> * </span>
                </label>
                <input
                  type="date"
                  className="form-control hdatepicker"
                  id=""
                  {...register("hospitalization_date", {
                    required: "Hospitalization Date is required",
                  })}
                  max={new Date().toISOString().split("T")[0]}
                  disabled={existingData?.hospitalization_date ? true : false}
                />
                {errors.hospitalization_date && (
                  <small className="text-danger">
                    {errors.hospitalization_date.message}
                  </small>
                )}
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label>
                  Discharge Date
                  <span className="error"> * </span>
                </label>
                <input
                  type="date"
                  required=""
                  {...register("discharge_date")}
                  autoComplete="off"
                  placeholder="Discharge Date"
                  onInput={(e) => validateDischargeDate(e.target.value)}
                  className="form-control hdatepicker"
                  disabled={existingData?.discharge_date ? true : false}
                  max={new Date().toISOString().split("T")[0]}
                />
                {errors.discharge_date && (
                    <small className="text-danger">
                      {errors.discharge_date.message}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label>
                  Estimated / Report Amount (₹)
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  onInput={(e) => validateNumberInput(e)}
                  placeholder="Enter Estimated / Claim Amount (₹)'"
                  {...register("report_amount", {
                    required: "Amount is required",
                    pattern: {
                      value: /^(0|[1-9]\d*)$/,
                      message: "invalid input amount",
                    },
                  })}
                  disabled={existingData?.report_amount ? true : false}
                />
                {errors.report_amount && (
                  <small className="text-danger">
                    {errors.report_amount.message || ""}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="cause_claim_submission">
                  Reason For Claim
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  name="reason_for_claim"
                  className="form-control"
                  id="reason_for_claim"
                  placeholder="Enter Reason for claim"
                  {...register("reason_for_claim", {
                    required: "Reason for claim is required",
                  })}
                  disabled={existingData?.reason_for_claim ? true : false}
                />
                {errors.reason_for_claim && (
                  <small className="text-danger">
                    Reason for claim is required
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="hospitalname_claim_submission">
                  Name of the Hospital
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Name of Hospital"
                  {...register("hospital_name", {
                    required: "Hospital name is required",
                  })}
                  disabled={existingData?.hospital_name ? true : false}
                />
                {errors.hospital_name && (
                  <small className="text-danger">
                    {errors.hospital_name.message || ""}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="hospital_location_claim_submission">
                  Hospital Address
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Name of Hospital"
                  {...register("hospital_address", {
                    required: "Hospital Address is required",
                  })}
                  disabled={existingData?.hospital_address ? true : false}
                />
                {errors.hospital_address && (
                  <small className="text-danger">
                    {errors.hospital_address.message || ""}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="pincode_claim_submission">
                  Pincode
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pincode"
                  maxLength={6}
                  onInput={(e) => validateNumberInput(e)}
                  placeholder="Enter Pincode"
                  {...register("pincode")}
                  disabled={existingData?.pincode ? true : false}
                />
                {errors.pincode && (
                  <small className="text-danger">Pincode is required</small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="city_claim_submission">
                  City
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                  {...register("city")}
                  disabled={existingData?.city ? true : false}
                />
                {errors.city && (
                  <small className="text-danger">City is Required</small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="state_claim_submission">
                  State
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Enter State"
                  {...register("state")}
                  disabled={existingData?.state ? true : false}
                />
                {errors.state && (
                  <small className="text-danger">State is required</small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="cause_claim_submission">
                  Claim Type
                  <span className="error"> * </span>
                </label>
                <select
                  className="form-control"
                  {...register("claim_type", {
                    required: "Claim Type is required",
                  })}
                  disabled={existingData?.claim_type ? true : false}
                >
                  <option value="">Select claim type</option>
                  <option value="Reimbursement">Reimbursement</option>
                  <option value="Cashless">Cashless</option>
                </select>
                {errors.claim_type && (
                  <small className="text-danger">Select Claim Type</small>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-3">
              <div className="form-group">
                <label htmlFor="state_claim_submission">
                  Remark
                  <span className="error"> * </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Enter Remark"
                  {...register("remark")}
                />
              </div>
            </div>
          </div>
          <div className="submit-btn-box">
            <input
              type="submit"
              className="register-form-btn mt-4 mb-3"
              value={isLoading ? "please wait..." : "Next"}
              disabled={isLoading}
            ></input>
          </div>
        </form>
        {!modalFlag &&
          <ClaimExistModal  isOpen={isOpen}  openModal={openModal} closeModal={closeModal} claimExistMsg={claimExistMsg}/>
        }
        <ClaimSubmisionModal errorMessages={errorMessages} resStatus={resStatus} setCurrentFormIndex={setCurrentFormIndex} isLoading={isLoading} isOpen={modalFlag}  closeModal={closeKycForm} claimExistMsg={submissionMsg || errorMessages}/>
      </div>
    </>
  );
};

export default Claim_details_form;
