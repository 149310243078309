import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import NoDataFound from "../components/NoDataFound";
import ContentLoader from "react-content-loader";
import { pdficon, downloadpdf, Hr_Icon } from "../Assets/index";
import { NotokenFound } from "../Utils";
import { AppContext } from "../ContextApi";

const Hr_Circular = () => {
  const [circularData, setCircularData] = useState([]);
  const [documentPath, setDocumentPath] = useState("");
  const [circularDataMsg, setCircularDataMsg] = useState("");
  const [isDataLoading, setDataLoading] = useState(true);
  const { requestOptions, setRequestOptions } = useContext(AppContext);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );

  const navigate = useNavigate();

  async function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);

      if (token) {
        try {
          const response = await fetch( process.env.REACT_APP_API_URL +
            `/employee/circulus-document-list`,
            requestOptions
          );
          const tokenizedResponse = await handleTokenInResponse(response);
          const res = await tokenizedResponse.json();

          if (res.success) {
            setCircularData(res.data.circulus_documents);
            setDocumentPath(res.document_path);
            console.log(res);
            setDataLoading(false);
          } else {
            setCircularData(res.data);
            setCircularDataMsg(res.message);
            setDataLoading(false);
          }
        } catch (error) {
          console.error("Error fetching OTP:", error);
        }
      }
    };

    fetchData();
  }, [token]);

  useEffect(() =>{
    if(!token ){
      NotokenFound(navigate)
    }
  },[])

  return (
    <>
      <Header />
      <div className="download-main-cover-wrapper">
        <h3 className="text-center">HR Circular</h3>
        <div className="download-main-section-wrapper">
          <div className="circular-section-cover mt-3">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-10">
                  <div className="download-forms-banner-img circular-banner-img">
                    <img src={Hr_Icon} alt="download-icon" />
                  </div>
                  <div className="row">
                    {isDataLoading ? (
                      <ContentLoader />
                    ) : circularData.length === 0 ? (
                      <NoDataFound msg={circularDataMsg}/>
                    ) : (
                      <div className="col-lg-12">
                        <div className="row d-flex justify-content-center">
                          {circularData.map((elem, ind) => (
                            <div className="col-lg-5">
                              <Downloadformlist
                                data={elem}
                                documentPath={documentPath}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div className="download-forms-banner-img">
                    <img src={Hr_Icon} alt="download-icon" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Downloadformlist = ({ data, documentPath }) => {
  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="download-form-content-box">
      <li className="download-form-list circular-pdf-list">
        <div className="download-circular-box">
          <div className="download-form-list-header">
            <div className="pdf-icon-box">
              <img src={pdficon} alt />
            </div>
          </div>
          <div
            className="download-icon-box"
            onClick={() => {
              handleDownload(`${documentPath}/${data.file_name}`);
            }}
          >
            <img src={downloadpdf} alt="pdf-download-icon" />
          </div>
        </div>

        <div className="circular_description">
          <h5>{data.file_name}</h5>
          <p>{data.discription}</p>
        </div>
      </li>
    </div>
  );
};

export default Hr_Circular;
