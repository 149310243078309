import React from 'react'

const DataLoadComponent = () => {
  return (
    <>
        <div class="custom-loader"></div>
    </>
  )
}

export default DataLoadComponent

