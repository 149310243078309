import React from 'react'
import ContentLoader from 'react-content-loader'

const CardLoaderWrapper = () =>{
    return (
        <div className="Card_loader_main_wrapper mt-5">
            <div className='row d-flex justify-content-center'>
                <div className="col-lg-5 mb-3">
                    <ContentLoader/>
                </div>
                <div className="col-lg-5 mb-3">
                    <ContentLoader/>
                </div>
                <div className="col-lg-5 mb-3">
                    <ContentLoader/>
                </div>
                <div className="col-lg-5 mb-3">
                    <ContentLoader/>
                </div>
            </div>
        </div>
    )
}

const Card = props => {
  return (
    <ContentLoader viewBox="0 0 302 140" height={160} width={560} {...props}>
  <circle cx="50" cy="60" r="35" />

  <rect x="100" y="20" rx="5" ry="5" width="320" height="10" />
  <rect x="100" y="40" rx="5" ry="5" width="120" height="10" />
  <rect x="100" y="60" rx="5" ry="5" width="140" height="10" />
  <rect x="100" y="80" rx="5" ry="5" width="270" height="10" />
</ContentLoader>
  )
}

export default CardLoaderWrapper