import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { claim_info } from "../Assets";
import LoadingData from "./LoadingAnimation";
import SuccessFull from "./SuccessFull";

const ClaimSubmisionModal = ({
  
  errorMessages,
  resStatus,
  isOpen,
  isLoading,
  setCurrentFormIndex,
  closeModal,
  claimExistMsg,
}) => {

  const handleCloseModal = () => {
    if (!resStatus) {
      closeModal();
    } else {
      closeModal();
      setCurrentFormIndex(1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="compare-error-main-cover">
        <Modal
          show={isOpen}
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          className="modal-compare-cover-box"
          centered
        >
          {isLoading ? (
            <LoadingData />
          ) : (
            <Modal.Body className="modal-add-to-compare-main-cover">
              <div className="close-compare-modal">
                <IoIosCloseCircle onClick={closeModal} />
              </div>
              <div className="claim_exist_modal_cover-wrapper">
                <div className="claim_exist-icon_cover">
                  {Object.keys(errorMessages)?.length === 0 ? (
                    <SuccessFull />
                  ) : (
                    <img src={claim_info} alt="claim msg" />
                  )}
                </div>
                {errorMessages && errorMessages.length > 0 ? (
                  Object.entries(errorMessages).map(([key, value]) => (
                    <h5 key={key}>{value}</h5>
                  ))
                ) : (
                  <h5>{claimExistMsg}</h5>
                )}
              </div>
              <div className="ok-btn">
                <button onClick={handleCloseModal}>OK</button>
              </div>
            </Modal.Body>
          )}
        </Modal>
      </div>
    </>
  );
};

export default ClaimSubmisionModal;
